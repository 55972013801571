.services {
    padding-bottom: 48px;

    &__container {
        position: relative;
        background-color: $primary;
        width: 100%;
        padding: 48px 24px 16px 24px;
        border-radius: 8px;
        margin-top: 48px;

        h4 {
            color: $white;
            font-weight: 700;
            margin: 0;
        }
        
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
        }

        &-icon {
            color: $neutralTertiary;
            opacity: .8;
            vertical-align: middle;

            &.active {
                color: $greenLight;
                opacity: 1;
            }

            &.help {
                color: $neutralTertiaryAlt;
                opacity: 1;
            }
        }

        &-description { color: $white; }

        &-avatar {
            position: absolute;
            top: -32px;
            left: 24px;
            border: solid 4px $white;
        }

        &-form {
            background-color: $white;
            padding: 16px;
            border-radius: 8px;
        }

        &--list {
            max-height: 250px;
            overflow-y: scroll;
            padding: 4px;
            padding-right: 12px;
            margin: 4px 0;
            margin-bottom: 20px;
            background-color: $lighterAlt;

            &-item {
                cursor: pointer;
                &:hover {
                    background-color: $lighter;
                }
            }
        }

    }
}