.articles {
    &__edit {
        &-header {
            h4 {
                text-transform: capitalize;
            }
        }
    }

    &__add {
        &--submit {
            width: 100%;
            margin: 0 auto;
            margin-top: 32px;
            
            @include breakpoint(md) {
                width: 75% !important;
            }
    
            @include breakpoint(xl) {
                width: 50% !important;
            }
        }
    }

}