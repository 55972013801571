.ant-pro {
    &-layout {
        &-bg-list {
            opacity: .05;
        }
    }
    &-sider {
        &-menu {
            padding-top: 24px;
        }
    }
    &-top-nav-header {
        &-logo {
            > :first-child {
                > img {
                    height: 24px;
                }
                > h1 {
                    color: $primary;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
    }
    &-base-menu {
        &-item {
            &-text {
                font-weight: 500;
            }
            &-icon {
                font-size: 16px !important;
            }
        }
    }
}
