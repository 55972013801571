.ant-modal-content {
    background-color: $neutralLighterAlt;

    .trama {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;
        height: 100%;
        left: 0;
        opacity: .1;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

.modal__confirm-delete {
    &.icon {
        color: $red;
        background-color: $redLight;
        padding: 4px;
        border-radius: 4px;
    }
}

.ant-modal-confirm {
    &-title {
        font-size: 17px !important;
        font-weight: 600 !important;
    }
    &-content {
        font-size: 15px !important;
    }
    &-btns {
        button {
            display: inline;

            
        }
    }
}

.ant-btn-default {
    background-color: $neutralLighterAlt;
    border-color: $primary;
    color: $neutralPrimary;
    font-weight: 600;
    border-radius: 4px;
    margin-right: 10px;
    &:hover {
        background-color: $neutralLighterAlt;
        border-color: $primary;
        color: $neutralPrimary;
    }
}
