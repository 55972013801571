.profile {
    h4 {
        margin-bottom: 32px;
    }

    &__manage-tabs {
        height: 220px;
        margin-top: 32px;

        h4 {
            margin-bottom: 32px;
        }

        &-forms {
            padding-top: 32px;
            padding-bottom: 56px;
        }
    }

}