.ant-page-header {
    padding: 0;
    padding-bottom: 24px;

    &-back {
        svg {
            vertical-align: middle;
        }
    }
    
    &-heading {
        align-items: center;

        &-left {
            margin: 0;
            margin-block: 0 !important;
        }
        
        &-title {
            color: $primary !important;
            font-size: 24px !important;
        }
    }

    &-content {
        padding-top: 4px;
    }

    &-footer {
        margin-top: 24px;
    }
}