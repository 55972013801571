.ant-btn {
    height: fit-content;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: $white;

    &-primary {
        background: $primary;
        border-color: $primary;

        &:hover {
            background: $primary;
            border-color: $primary;
        }
    }

    &-ghost {
        &:hover {
            color: $primary;
            border-color: $primary;
        }
    }

    &-dashed {
        border-color: $primary;

        span, svg {
            display: flex;
            color: $primary;
        }

        &:hover {
            color: $primary;
        }
    }

    &-text {
        color: $primary;

        &:focus {
            color: $primary;
        }

        &:hover {
            color: $primary;
        }
    
    }
}

.action {
    
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        padding: 4px;
        border-radius: 50%;
        transition: all .15s .15 ease-in-out;

        &:hover {
            background-color: $neutralLight;
            transition: all .15s .15 ease-in-out;
        }

        &:active {
            background-color: $neutralLighter;
            transform: scale(0.95);
            transition: all .15s .15 ease-in-out;
        }

        &-icon {
            font-size: 20px;
            color: $primary;
        }
    }
}