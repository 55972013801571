.empty-data {
    background-color: $lighter;
    border-radius: 4px;
    padding: 4px 8px;
    color: $primary;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: 14px;
    font-weight: 600;

    &-icon {
        margin: 0;
        margin-right: 8px;
        vertical-align: middle;
    }
    
    &-text {
        cursor: pointer;
        margin-left: 6px;
    }
}