.editor {
    min-height: 100%;
    max-height: 370px;
    width: 100%;
    overflow-y: scroll;
    border-radius: 4px;

    &__quill {
        height: 100%;
        max-height: 600px;
        overflow: scroll;
    }
}