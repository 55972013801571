.ant-alert-error {
    .ant-alert {
        &-icon {
            color: $red;
        }
        &-message {
            color: $red;
            font-weight: 600;
            font-size: 15px;
        }
        .anticon-close {
            color: $white;
        }
    }
}

.ant-alert-success {
    .ant-alert-icon {
        color: $green;
    }
    .ant-alert-message {
        color: $green;
        font-weight: 600;
        font-size: 15px;
    }

    .anticon-close {
        color: $green;
    }
}

.ant-alert-warning {
    background-color: $yellowLight;
    border-color: $yellow;

    .ant-alert-icon {
        color: $orangeLighter;
    }
    .ant-alert-message {
        color: $orangeLighter;
        font-weight: 600;
        font-size: 15px;
    }

    .anticon-close {
        color: $yellow;
    }
}