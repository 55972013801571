.location {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 24px;

    &__label {
        color: $primary;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        width: 100%;
        display: block;
    }

    &__map {
        position: relative;
        flex: 1;
    }

    &__pin {
        position: absolute;
        top: calc(50% - 18px);
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: 16vh;
    }
}
