.ant-menu {
    &-item {
        &:after {
            display: none !important;
        }
        &-selected {
            color: $primary !important;
            background-color: $neutralLighter !important;

            a {
                color: $primary !important;
            }
        }

        &:active {
            background-color: $neutralLighter !important;
        }

        &:hover {
            color: $primary !important;
            background-color: $neutralLighter !important;

            a {
                color: $primary !important;
            }
        }
    }

    &-horizontal {
        &:not(.ant-menu-dark) {
            & > .ant-menu-item-selected::after {
                border-bottom: 2px solid $primary !important;
            }
        }
    }
}
