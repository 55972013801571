.tags {
    background-color: $lighterAlt;
    padding: 24px 12px;
    border-radius: 16px;
    gap: 24px;

    &__category {
        background-color: $white;
        border-radius: 100px;
        padding: 4px;
        padding-right: 12px;
        cursor: pointer;
        border: solid 1.4px transparent;
        gap: 8px;

        &-icon {
            background-color: $lighterAlt;
        }

        &.active {
            background-color: $primary;
            color: $white;

            &:hover {
                background-color: $primary;
                border: solid 1.4px $primary;
            }

            &-icon {
                background-color: $lighterAlt;
            }
        }

        &:hover {
            background-color: $lighterAlt;
            border-color: $lighter;
        }

    }
}

.card {
    &__summary {
        overflow: hidden;
        position: relative;
        display: -webkit-box;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 12px;
            background: linear-gradient(transparent, $white);
        }
    }

    &__actions {
        margin-top: 12px;
        width: 100%;
    }

    &__comments {
        background-color: $lighterAlt;
        margin-top: 24px;
        border-radius: 4px;

        &--comment {
            background-color: $white;
            border-radius: 4px;
            padding: 8px 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            width: 100%;
        }
    }

    &__post {
        background-color: $lighter;
        margin-top: 16px;
        padding: 16px;
        border-radius: 4px;
    }

    &__date {
        font-size: 10px;
        color: $neutralSecondary;
    }
}