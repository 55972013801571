
.onboarding {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 56px 24px;
    overflow-y: scroll;
    
    @include breakpoint(lg) { padding: 56px 0; }

    .trama {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .1;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: auto;
        
        @include breakpoint(md) {
            background-position: top center;
            background-size: cover;
        }
    }

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
    }

    .logout {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .icon {
            color: $neutralTertiaryAlt;
            margin-right: 8px;
            margin-top: 4px;
        }

        .text {
            color: $neutralTertiaryAlt;
            font-weight: 600;
            font-size: 16px;
        }
    }

    .steps {
        width: fit-content;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 24px auto 12px auto;
        
        @include breakpoint(md) {
            width: 570px;
            align-items: center;
            justify-content: center;
        }

        .step {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 3);
            
            @include breakpoint(md) { 
                margin-right: 24px;
            }

            &.active {
                .icon, .name { color: $primary; }
                .number {
                    background-color: $primary;
                    color: $white;
                }
            }

            &:last-child { margin-right: 0; }

            .icon {
                margin-right: 8px;
                font-size: 18px;
                color: $neutralTertiaryAlt;
                &.end {  margin-left: 4px; margin-top: 2px; }
            }

            .number {
                color: $neutralLighterAlt;
                background-color: $neutralTertiaryAlt;
                border-radius: 50%;
                padding: 1px 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                font-weight: 600;
                font-size: 14px;
            }

            .name {
                color: $neutralTertiaryAlt;
                font-weight: 600;
                font-size: 18px;
            }
        }

    }

    .section {
        background-color: $white;
        position: relative;
        border-radius: 12px;
        margin: 12px 0;
        padding: 24px;
        width: 100%;
    
        @include breakpoint(md) {
            width: 90%;
            margin: 0 auto;
        }

        @include breakpoint(lg) {
            width: 90%;
            margin: 0 auto;
        }

        @include breakpoint(xl) {
            width: 80%;
            margin: 0 auto;
        }

        @include breakpoint(xxl) {
            width: 70%;
            margin: 0 auto;
        }
    }

    &__form { margin-top: 48px; }

}

.membership__card {
    height: auto;
    background-color: $white;
    margin-bottom: 24px;

    @include breakpoint(xl) { height: 350px; margin: 0; }
    @include breakpoint(xxl) { height: 330px; margin: 0; }

    &.active.GOLD { border: 2.5px solid $gold; }
    &.active.PLATINUM { border: 2.5px solid $platinum; }
    &.active.DIAMOND { border: 2.5px solid $diamond; }
    
    &--avatar { img { object-fit: contain; } }
    
    &--title {
        font-weight: 700;
        font-size: 18px;
    }

    &--target {
        font-weight: 600;
        font-size: 18px;
        width: 100%;
        margin-bottom: 16px;
        display: block;
    }

    &--title {
        font-size: 16px; font-weight: 700;
    }

    &--paragraph {
        font-size: 15px;
    }
}    