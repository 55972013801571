@import '../../styles/colors';
@import '../../styles/utils';

.modal {
    font-size: 16px;
    width: 90% !important;
    @include breakpoint(md) { width: 1100px !important; }
    
    .body {
        position: relative;
        margin-top: 12px;
        padding: 0;
        padding-top: 8px;
        backdrop-filter: blur(2px);
        background: hsla(0,0%,100%,.2);
        border-radius: 4px;
        z-index: 2;
    }
}

.containerForm {
    padding: 24px 8px;
    max-height: 800px;
    overflow-y: scroll;
}

.terms {
    margin-bottom: 20px !important;

    .containerClauses {
        height: 400px;
        overflow-y: scroll;
        padding: 16px 0;
        padding-right: 16px;
    }

    .clauses { padding: 12px 16px; }
    .item { text-align: justify; }
}