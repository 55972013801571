.home {
    &__welcome {
        background-color: $white;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        color: $primary;
        margin-bottom: 24px;
        padding: 16px 24px;
        width: 100%;
    }

    &__summary {
        &-item {
            background-color: $white;
            border-radius: 8px;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
            border: solid 2px $primary;
            padding: 16px 24px;
            width: 100%;
            margin-right: 0;
            margin-bottom: 16px;

            @include breakpoint(md) {
                width: fit-content;
                margin-right: 16px;
            }

            > h4, p {
                color: $primary;
            }

            &.completed {
                border-color: $green;
                background-color: $green;

                > h4 {
                    color: $white;
                }

                > p {
                    color: $white;
                    font-weight: 600;
                }
            }

            &.none {
                border-color: $yellow;
                background-color: $yellow;

                > h4 {
                    color: $primary;
                }

                > p {
                    color: $primary;
                    font-weight: 600;
                }
            }

            > p {
                font-size: 15px;
                line-height: 20px;
                margin: 0;
            }
        }

    }
}