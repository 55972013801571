.ant-table {
    border-radius: 8px;

    &-thead {
        > tr {
            > th {
                background-color: $primary;
                color: $white;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.05px;
                padding: 4px 8px;
            }
        }
    }

    &-tbody {
        > tr {
            > td {
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 0.05px;
                padding: 12px 14px;
            }
        }
    }
}

.table {

    &__summary, &__title {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__help {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    &__category {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 4px;
        padding: 2px 6px;
        background-color: $lighter;
        color: $primary;
        width: fit-content;
        margin: 0 auto;

        &.legacy {
            background-color: $legacy;
            color: $white;
        }
        &.road_map {
            background-color: $roadMap;
        }
        &.think_tank {
            background-color: $thinkTank;
        }
        &.brands {
            background-color: $brands;
        }
    }

    &__external-link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        background-color: $neutralLighter;
        color: $primary;
        border-radius: 4px;
        padding: 2px 6px;
        width: fit-content;
        cursor: pointer;

        &-icon {
            color: $primary;
            vertical-align: text-bottom
        }
    }

    &__not-available {
        border: solid 1.4px $neutralTertiary;
        padding: 2px 6px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;

        &.ACTIVE {
            border-color: $primary;
            color: $primary;
        }

        &.FINISHED {
            border-color: $green;
            color: $green;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        &-icon {
            border-radius: 4px;
            padding: 4px;
            background-color: $lighter;
            color: $primary;

            &.delete {
                color: $red;
                background-color: $redLight;

                &:hover {
                    background-color: $redLighter;
                }
            }

            &:hover {
                cursor: pointer;
                background-color: $lighterAlt;
            }

            &:active {
                transform: scale(0.96);
            }
        }
    }
}