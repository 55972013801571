.ant-descriptions-item {

    &-container {
        align-items: center;
    }
    &-label {
        color: $primary;
        font-weight: 600;
    }
    &-content {
    }
}