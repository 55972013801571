.flex-row {
    display: flex;
    flex-flow: row;
  }
  
  .start-row-start {
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .start-row-center {
    justify-content: flex-start;
    align-items: center;
  }
  
  .start-row-end {
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .center-row-start {
    justify-content: center;
    align-items: flex-start;
  }
  
  .center-row-center {
    justify-content: center;
    align-items: center;
  }
  
  .center-row-end {
    justify-content: center;
    align-items: flex-end;
  }
  
  .end-row-start {
    justify-content: flex-end;
    align-items: flex-start;
  }
  
  .end-row-center {
    justify-content: flex-end;
    align-items: center;
  }
  
  .end-row-end {
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  
  /* Align Column */
  
  .flex-column {
    display: flex;
    flex-flow: column;
  }
  
  .start-column-start {
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .start-column-center {
    justify-content: flex-start;
    align-items: center;
  }
  
  .start-column-end {
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .center-column-start {
    justify-content: center;
    align-items: flex-start;
  }
  
  .center-column-center {
    justify-content: center;
    align-items: center;
  }
  
  .center-column-end {
    justify-content: center;
    align-items: flex-end;
  }
  
  .end-column-start {
    justify-content: flex-end;
    align-items: flex-start;
  }
  
  .end-column-center {
    justify-content: flex-end;
    align-items: center;
  }
  
  .end-column-end {
    justify-content: flex-end;
    align-items: flex-end;
  }
  