.loading {
    &__section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        width: 100%;
        height: 100%;

        h4 {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
        }
    }
}